<template>
	<div class="Brand">

		
		<div class="Coupons">
			
			<div class="Coupon" v-for="coupon in CouponList" :key="coupon.Id">
				
				<div class="CouponBorder">
					
					<div class="Money">
						<div class="Ico">
							<span class="el-icon-money"></span>
						</div>
						<div class="Inf">
							<div class="Lab">￥</div>
							<div class="Price">{{coupon.CuponMoney}}</div>
						</div>
					</div>
					
					<div class="Info">
						<ul>
							<h3>{{coupon.Name}}<span>({{coupon.StatusName}})</span></h3>
							<p>范围:{{coupon.ShopName}}</p>
							<p>条件:满￥{{coupon.MoneyStart}}减￥{{coupon.CuponMoney}}</p>
							<p>时间:{{coupon.StartAt2}} 至 {{coupon.EndAt2}}</p>
						</ul>
					</div>
					
					<div class="Action">
						
						<a :href="'/#/shop/'+coupon.ShopId" target="_blank" v-if="coupon.Status == 50 && coupon.ShopId != 'all'" class="Get">使用</a>
						<a href="/#/goods/goods_list" target="_blank" v-else-if="coupon.Status == 50 && coupon.ShopId == 'all'" class="Get">使用</a>
						
					</div>
					
				</div>
				
			</div>
			
			
		</div>
		
	

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MyCouponList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Status:-1,//全部
				  Id:'',//商品ID
				  Name:'',//关键词
			  },
			  CouponList:[],
			  Page:1,
			  PageSize:15,
			  Total:0,
			  IsApply:false
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSeaching()
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSeaching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'CouponRecive',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:100,
					Status:this.Search.Status,
					FromCouponChannel:'mycenter',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.CouponList = res.Data.CouponList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'CouponRecive',
		  			Action: 'Count',
					Status:this.Search.Status,
					FromCouponChannel:'mycenter',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.Brand .List{
}
.C1,.C2,.C3,.C4,.C5,.C6{
	width: 150px;
}
.C1{
	padding-left: 10px;
	width: 60px;
	margin-right: 20px;
}
.C1 img{
	width: 60px;
}
.C2{
	flex: 1;
	margin-right: 10px;
}
.C4 i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 5px;
}
.C5{
	color: rgba(0,0,0,0.3);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Brand .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Brand .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Brand .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}

.Coupons{
	margin-top: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.Coupons .Coupon{
	margin-bottom: 20px;
	width: 48%;
	height: 180px;
	background-color: #ffffff;
	padding: 5px;
	border: 2px dotted rgba(126,168,118,1);
}
.Coupons .Coupon .CouponBorder{
	background-color: rgba(126,168,118,1);
	display: flex;
	width: 100%;
	height: 100%;
}
.Coupons .Coupon .CouponBorder .Money{
	display: flex;
	align-items: center;
	height: 100%;
	margin-left: 20px;
	margin-right: 40px;
	color: #ffffff;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
.Coupons .Coupon .CouponBorder .Money .Ico{
	width: 100%;
	font-size: 2.0rem;
}
.Coupons .Coupon .CouponBorder .Money .Inf{
	display: flex;
	align-items: center;
}
.Coupons .Coupon .CouponBorder .Money .Lab{
}
.Coupons .Coupon .CouponBorder .Money .Price{
	font-size: 2.0rem;
}
.Coupons .Coupon .CouponBorder .Info{
	flex: 1;
	display: flex;
	align-items: center;
	height: 100%;
	flex-wrap: wrap;
	margin-right: 20px;
}
.Coupons .Coupon .CouponBorder .Info h3{
	width: 100%;
	padding: 0px;
	margin: 0px;
	color: #ffffff;
	font-size: 1.3rem;
}
.Coupons .Coupon .CouponBorder .Info h3 span{
	font-size: 1.0rem;
	padding-left: 10px;
	font-weight: normal;
	color: rgba(255,255,255,0.4);
}
.Coupons .Coupon .CouponBorder .Info p{
	width: 100%;
	margin: 0px;
	padding: 0px;
	margin-top: 5px;
	color: rgba(255,255,255,0.8);
}
.Coupons .Coupon .CouponBorder .Action{
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: 20px;
}
.Coupons .Coupon .CouponBorder .Action .Get{
	background-color: rgba(255,255,255,0.8);
	width: 80px;
	height: 80px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	color: rgba(126,168,118,1);
	cursor: pointer;
}
.Coupons .Coupon .CouponBorder .Action .Get:hover{
	background-color: rgba(255,255,255,1);
}
</style>
